

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <p>© 2024 Sam GRANDVINCENT, All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;